import React from "react"
import styled from "styled-components"

const SCenteredText = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
`

const CenteredText: React.FC = ({ children }) => {
	return <SCenteredText>
    {children}
	</SCenteredText>
}

export default CenteredText
